<template>
  <div>
      <p>This is the Tasks view. it is a List of Tasks and actions. Possibly these relate to tickets.. or users.. ?? this may actially sit uner the tickets folder.. for discussion later!!</p>
  </div>
</template>

<script>
export default {
  created() {
    this.getView()
  },
  methods: {
    getView() {
            this.$http.get('/ViewAccess/desktasks')
            .then(() => {
            })
            .catch(() => {
            })
        },
  }
}
</script>

<style>

</style>
